.PostCard {
  text-decoration: none;
  margin: 2rem;
  background: #d0d1d3;
  color: #112432;
  border: 1px solid var(--lightGrey);
  border-radius: var(--borderRadius);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.15s ease-in-out;
  overflow: hidden;
}

.PostCard:hover,
.PostCard:focus {
  transform: translateY(-1px);
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
}

.PostCard--Image {
  margin: 0 0 2rem 0;
  width: 100%;
  height: 15rem;
}

.PostCard--Title {
  margin: 0;
}

.PostCard--Content {
  padding: 0 2.5rem 2.5rem;
}

.PostCard--Category {
  font-size: 1rem;
  text-align: right;
}
