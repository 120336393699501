:root {
  --primary: #802558;
  --primary-light: #d15099;
  --secondary: #d0d1d3;
  --lightGrey: #1a2831;
  --midGrey: #cacaca;
  --darkGrey: #272727;
  --danger: #ff3d3d;
  --font-primary: 'cardo', serif;
  --font-system: 'Montserrat', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  --borderRadius: 3px;
}
html {
  box-sizing: border-box;
  font-size: 62.5%;
  min-height: 100%;
  background: var(--lightGrey);
}

body {
  font-family: var(--font-system);
  min-height: 100%;
  position: relative;
  background: #112432;
  color: var(--secondary);
  font-size: 1.6em;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
}

a {
  color: var(--primary);
}

strong {
  font-weight: 600;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 0.5em;
}

pre {
  background: var(--lightGrey);
  line-height: 1.45;
  font-size: 85%;
  border-radius: 3px;
  padding: 16px;
}

code {
  font-size: 85%;
  padding: 0.15em 0;
  color: var(--secondary);
  background: var(--lightGrey);
  border-radius: 3px;
}
code:before,
code:after {
  letter-spacing: -0.2em;
  content: '\00a0';
}
pre code {
  font-size: inherit;
}
pre code:before,
pre code:after {
  display: none;
}
blockquote {
  margin-left: 0;
  padding-left: 1em;
  line-height: 1.1;
  font-size: 1em;
  font-style: italic;
  border-left: 2px solid var(--primary);
}

/* Utility Classes */
.light {
  background-color: var(--lightGrey);
}
.white {
  background-color: white;
}
.dark {
  background-color: var(--primary);
  color: white;
}
.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6 {
  color: white;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.taCenter {
  text-align: center;
}
.taLeft {
  text-align: left;
}
.taRight {
  text-align: right;
}

/* Layout */

.section {
  width: 100%;
  padding: 7.5rem 0;
}
.section.thick {
  padding: 10rem 0;
}
.section.thin {
  padding: 3.5rem 0;
}
.section.noPadding {
  padding: 0;
}

.container {
  max-width: 1111px;
  width: 90%;
  margin: 0 auto;
}
.container.skinny {
  max-width: 888px;
}
.container.skinnier {
  max-width: 555px;
}

/* Buttons */

.Button {
  background: var(--primary);
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 0.05em;
  font-size: 1.4rem;
  display: inline-block;
  padding: 1.25rem 3rem;
  border: none;
  transition: all 0.2s ease;
  cursor: pointer;
  border-radius: var(--borderRadius);
}
.Button:hover,
.Button:focus {
  background: var(--primary-light);
  outline: none;
}
.Button[disabled] {
  opacity: 0.4;
  pointer-events: none;
  cursor: progress;
}
.Button.Button--inverted {
  background: white;
  border-color: white;
  color: var(--primary);
}
.Button.Button--inverted:hover,
.Button.Button--inverted:focus {
  background: transparent;
  border-color: currentColor;
  color: white;
  background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0)),
    linear-gradient(
      -32deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0)
    );
}
