.LinkList {
  width: 40px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
}

@media (max-width: 650px) {
  .DrawerToggle {
    width: 10vw;
    margin: 1.2vh;
  }
  .DrawerToggle div {
    width: 10vw;
    height: 1vh;
    margin: 10%;
    background-color: #802558;
  }
}
