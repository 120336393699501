.Nav {
  position: sticky;
  top: 0;
  z-index: 1;
}

.Nav--Container {
  display: flex;
  align-items: center;
  height: 6rem;
}

.Nav .Logo {
  margin-right: 3rem;
}
@media (max-width: 500px) {
  .Nav--Container {
    display: none;
  }
  .Logo {
    display: block;
  }
}
